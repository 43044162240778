import {
	deletePost,
	deletePostFromStore,
	getPostDetails,
	setActivePost,
	getPostReadList,
	clearPostReadList
} from 'actions/posts';
import { getReaderParameters } from 'actions/user';
import Date from 'components/Presentation/Date/Date';
import PostContent from 'components/Presentation/PostContent';
import PostForm from 'containers/Forms/Post';
import Modal from 'containers/Modals/Modal';
import { trackImmersiveReaderError } from 'helpers/insights';
import { getRootUrl } from 'helpers/url';
import React, { Component } from 'react';
import { translate } from '@haldor/ui';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import swal from 'sweetalert2';
import { Spinner } from 'UI';
import Block, { BlockWrapper } from 'UI/Elements/Block';
import { Immersive_Reader } from 'UI/Icons';
import { Block as ContentBlock, Button, Icon, Flex } from '@haldor/ui';
import Post from '_class/Post';
import User from '_class/User';
import { setPageTitle, setPageActions } from 'actions/header';
import FileItem from 'components/FileItem';
import ProgressBar from 'UI/Elements/ProgressBar';
import Moment from 'moment';
import FromNow from 'components/Presentation/Date/FromNow';
import DisplayName from 'components/Presentation/DisplayName';

const ImmersiveReader = require('@microsoft/immersive-reader-sdk');

class SinglePost extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loadingReadlist: true,
			modalIsOpen: false,
			relationshipList: false,
			showPostNotFound: false
		};
	}

	componentWillUnmount = () => {
		this.props.setActivePost();
		this.props.clearPostReadList();
		ImmersiveReader.close();
	}

	componentDidMount = async () => {
		const { posts, currentUser, match } = this.props;

		if (posts.length > 0) {
			await this.props.setActivePost(match.params.id);
		}

		await this.props.getPostDetails(match.params.id).catch((error) => {
			if (error.status == 204) {
				this.setState({ showPostNotFound: true });
			}
		});

		if (this.props.activePost != null) {
			if ((this.props.activePost.creator == currentUser.id || this.props.activePost.editable)) {
				this.props.getPostReadList(match.params.id).then(() => {
					this.setState({ loadingReadlist: false });
				});
			} else {
				this.props.clearPostReadList();
			}

			this.props.setPageTitle(this.props.activePost.title);
		} else {
			this.props.clearPostReadList();
		}
	}

	editButton = () => {
		return (
			<div>
				<Button type="secondary" style={{ marginTop: 5 }} onClick={this.openModal}>
					{this.props.translate('Edit')}
				</Button>
			</div>
		);
	}

	deleteButton = () => {
		return (
			<div>
				<Button type="secondary" style={{ marginTop: 5 }} onClick={this.openDeleteModal}>
					{this.props.translate('Delete')}
				</Button>
			</div>
		);
	}

	openDeleteModal = () => {
		const { translate } = this.props;

		swal.fire({
			title: translate('Delete'),
			text: translate('delete-post-prompt'),
			showCancelButton: true,
			cancelButtonText: translate('Cancel'),
			confirmButtonText: translate('Delete'),
		}).then(result => {
			if (result.value != null) {
				this.props.deletePost(this.props.match.params.id).then(() => {
					this.props.deletePostFromStore(this.props.match.params.id);
					this.props.history.push(getRootUrl());
				});
			}
		});
	}

	closeDeleteModal = () => {
		this.setState({ deleteModal: false });
	}

	openModal = () => {
		this.setState({ modalIsOpen: true });
	}

	closeModal = (proxy, skipPrompt) => {
		this.props.getPostDetails(this.props.match.params.id).then(() => {
			this.props.getPostReadList(this.props.match.params.id).then(() => {
				this.setState({ loadingReadlist: false });
			});
			this.setState({ modalIsOpen: false });
			this.props.setPageTitle(this.props.activePost.title);
		});
	}

	runImmersiveReader = async () => {
		const post = new Post(this.props.activePost);
		const { currentUser } = this.props;
		const localLanguage = localStorage.getItem('language');

		const data = {
			title: post.getName(),
			chunks: [
				{
					content: '<h1>' + post.getName() + '</h1>',
					mimeType: 'text/html',
				},
				{
					content: post.getDescription(),
					mimeType: 'text/html',
				}
			],
		};

		await this.props.getReaderParameters();
		const { reader } = this.props;

		ImmersiveReader.launchAsync(reader.token, reader.subdomain, data, {
			uiLang: localLanguage, // Set immersive reader UI language to selected lang in Haldor Edu
		}).catch((error) => {
			console.error('error', error);
			trackImmersiveReaderError(error, currentUser.id);
		});
	}

	toggleRelationshipList = () => {
		const { readList, translate } = this.props;
		const targetAudience = this.props.activePost.targetAudience.toLowerCase();

		if ((readList != null && targetAudience != null && targetAudience == "guardians" && readList.filter(read => read.userType == 'GUARDIAN').length > 200)
			|| (readList != null && targetAudience != null && targetAudience != "guardians" && readList.length > 200)) {
			swal.fire({
				title: '',
				text: translate('Since the post was shared with more than 200 people, you can not see who read it'),
				showCancelButton: false,
				confirmButtonText: translate('Ok'),
			})

			return true;
		}

		this.setState({ relationshipList: !this.state.relationshipList });
	}

	renderReadList = () => {
		const { translate, readList } = this.props;
		const { relationshipList } = this.state;
		const targetAudience = this.props.activePost.targetAudience.toLowerCase();

		if ((readList != null && targetAudience != null && targetAudience == "guardians" && readList.filter(read => read.userType == 'GUARDIAN').length > 200)
		|| (readList != null && targetAudience != null && targetAudience != "guardians" && readList.length > 200)) {
			return null;
		}

		let modalTitle = this.props.activePost.targetAudience.toLowerCase() == "guardians" ?
			translate('Shared with guardians only')
			: this.props.activePost.targetAudience.toLowerCase() == "students" ?
				translate('Shared with students only')
				: this.props.activePost.targetAudience.toLowerCase() == "all" ?
					translate('Shared with students and guardians')
					: translate('shared-with');


		return <Modal type="small" isOpen={relationshipList} overridePrompt onClose={this.toggleRelationshipList} title={modalTitle}>
			<div className="form single-post-read-list">
				{readList != null ?
					<div style={{ marginLeft: "1.5rem" }} >
						<div className="info-item" >
							<Icon bw name="Alert" />
							{translate('Recipients who have read the post are marked with')}
							<Icon name="Eye" title={this.props.translate('have-read')} />
						</div>
						{this.props.activePost.targetAudience.toLowerCase() == "students" ?
							readList.map((read) => {
								return read.hasRead ?
									<div className="user-item" key={read.userId} style={{ display: "flex" }}>
										<span style={{ display: "inline-flex", fontWeight: "bold" }}>
											<DisplayName
												firstName={read.user.firstName}
												lastName={read.user.lastName}
												email={read.user.email}
												data={readList && readList.length > 0 ? readList.map(x => x.user) : null}
											/>
											<Icon name="Eye" title={this.props.translate('have-read')} />
										</span>
										<div>&nbsp;</div>
									</div>
									:
									<div className="user-item" key={read.userId} style={{ display: "flex" }}>
										<span>
											<DisplayName
												firstName={read.user.firstName}
												lastName={read.user.lastName}
												email={read.user.email}
												data={readList && readList.length > 0 ? readList.map(x => x.user) : null}
											/>
										</span>
									</div>
							})
							: this.props.activePost.targetAudience.toLowerCase() == "guardians" ?
								readList.map((read) => {
									return <div className="user-item" key={read.userId} style={{ display: "flex" }}>
										{read.userType != 'GUARDIAN' && read.user.guardians != null && read.user.guardians.length > 0 ?
											<div style={{ paddingBottom: ".7rem" }}>
												<span>
													<DisplayName
														firstName={read.user.firstName}
														lastName={read.user.lastName}
													/>
												</span>

												{read.user.guardians
													.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
													.map((guardian) => {
														let foundGuardian = readList.find(read => read.userId == guardian.id && read.userType == 'GUARDIAN');
														const hasRead = foundGuardian && foundGuardian.hasRead;
														return <div style={{ display: "flex", marginLeft: "2rem", fontWeight: hasRead ? "bold" : "normal" }}>
															<span style={{ display: "inline-flex" }}>
																<DisplayName
																	firstName={guardian.firstName}
																	lastName={guardian.lastName}
																/>
																{hasRead ?
																	<Icon name="Eye" title={this.props.translate('have-read')} />
																	: ""}
															</span>
														</div>
													})}

											</div>
											: ""}
									</div>
								})
								: readList.map((read) => {
									return <div className="user-item" key={read.userId} style={{ display: "flex" }}>
										{read.userType != 'GUARDIAN' ?
											<div style={{ paddingBottom: ".7rem" }}>
												<span style={{ display: "inline-flex", fontWeight: read.hasRead ? "bold" : "normal" }}>
													<DisplayName
														firstName={read.user.firstName}
														lastName={read.user.lastName}
													/>
													{read.hasRead ?
														<Icon name="Eye" title={this.props.translate('have-read')} />
														: ""}
												</span>

												{read.user.guardians != null && read.user.guardians.length > 0 ?
													read.user.guardians.sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
														.map((guardian) => {
															let foundGuardian = readList.find(read => read.userId == guardian.id && read.userType == 'GUARDIAN');
															const hasRead = foundGuardian && foundGuardian.hasRead;
															return <div style={{ display: "flex", marginLeft: "2rem", width: "100%", fontWeight: hasRead ? "bold" : "normal" }}>
																<span style={{ display: "inline-flex" }}>
																	<DisplayName
																		firstName={guardian.firstName}
																		lastName={guardian.lastName}
																	/>
																	{hasRead ?
																		<Icon name="Eye" title={this.props.translate('have-read')} />
																		: ""}
																</span>
															</div>
														})
													: ""}
											</div>
											: ""}
									</div>
								})
						}
					</div>
					:
					<div className="color--meta text--center size-12">
						{this.props.translate('not-read-post')}
					</div>
				}
			</div>
		</Modal>
	}

	render() {
		const { activePost } = this.props;

		if (this.state.showPostNotFound) {
			return <div className="single-task">
				<div className="single-section">
					<ContentBlock>
						<div style={{ padding: '1rem 0' }} className="text--center color--meta weight--bold">
							{this.props.translate('You do not have permission to view the post')}
						</div>
					</ContentBlock>
				</div>
			</div>
		}

		if (typeof (activePost) == 'undefined' || activePost == null || Object.keys(activePost).length == 0) {
			return (
				<div>
					<Spinner center />
				</div>
			);
		}

		let readCount = 0;
		let percentage = 0;
		let sharedWithPeopleCount = 0;

		if (this.props.readList != null) {
			readCount = [...this.props.readList].filter(read =>
				read.hasRead
			).length;

			sharedWithPeopleCount = [...this.props.readList].length;

			if (this.props.activePost.targetAudience.toLowerCase() == "guardians") {
				sharedWithPeopleCount = [...this.props.readList].filter(read =>
					read.userType == "GUARDIAN"
				).length;

				readCount = [...this.props.readList].filter(read =>
					read.hasRead && read.userType == "GUARDIAN"
				).length;
			}

			percentage = Math.round(100 / sharedWithPeopleCount * readCount);
		}

		const user = new User(this.props.currentUser);
		const post = new Post(this.props.activePost);

		return (
			<div className="single-task">
				{!user.isStudent() ? this.renderReadList() : null}

				{activePost.creator == this.props.currentUser.id || activePost.editable ?
					<Modal isOpen={this.state.modalIsOpen} onClose={this.closeModal} title={this.props.translate('edit-post')}>
						<PostForm
							selectMultipleSchools={activePost.relationships.find(ss => ss.referenceType == 'SCHOOL') != null}
							onModalClose={this.closeModal}
						/>
					</Modal>
					: null}

				<div className="single-section form left-side" style={{ paddingTop: '0' }}>
					<ContentBlock>
						{activePost.status == 'POST_DRAFT' ?
							<div style={{ marginBottom: '.35rem' }}>
								{this.props.translate('Draft')}
							</div>
							: null}

						<div className="single-task-description">
							{activePost.published != null && Moment.utc(activePost.published).isAfter(Moment()) ?
								<div className="pull-right">
									<FromNow style={{ marginTop: 0 }}>
										{Moment.utc(activePost.published).local().format()}
									</FromNow>
								</div>
								: null}

							<span className="title">{this.props.translate('description')}</span>

							{this.props.services.immersiveReaderPosts ?
								<Flex center>
									<Link to="#" onClick={this.runImmersiveReader}>
										<div className="reader trigger">
											<Immersive_Reader />
											{this.props.translate('immersive-reader')}
										</div>
									</Link>
								</Flex>
								: null}

							<PostContent>{post.getDescription()}</PostContent>
						</div>

						<div style={{ marginTop: '1rem' }}>
							<span className="title">{this.props.translate('date')}</span>
							{activePost.status == 'POST_PUBLISHED' ?
								<Date>{activePost.published}</Date> : this.props.translate('Not-published')}
						</div>

						<div style={{ marginTop: '1rem' }}>
							<span className="title">{this.props.translate('column-created-by')}</span>

							{activePost.author != null ?
								activePost.author
								: null}
						</div>
						{!user.isStudent() ?
							<div style={{ marginTop: '1rem' }}>
								<span className="title">
									{this.props.translate('shared-with')}

									{this.props.activePost.targetAudience != null ?
										<span
											style={{ marginLeft: '.35rem', position: 'relative', top: '-2px' }}
											className="color--meta size-12"
										>
											({this.props.translate(this.props.activePost.targetAudience.toLowerCase()).toLowerCase()})
										</span>
										: null}
								</span>

								{this.props.activePost.relationships.map((relationship) => {
									return <div key={relationship.referenceId}>
										<DisplayName
											firstName={relationship.referenceTitle}
											email={relationship.referenceEmail}
											data={this.props.activePost.relationships}
										/>
									</div>
								})}
							</div>
							: null}
					</ContentBlock>

					{activePost.associatedFiles != null && activePost.associatedFiles.length > 0 ?
						<div className="preschool-gallery" style={{ width: '100%' }}>
							<BlockWrapper cols="2">
								{activePost.associatedFiles.map((file, index) => {
									if (file.type != null && file.type.indexOf('image') > -1) {
										return <Block key={`file-${file.id}-${index}`}>
											<FileItem
												file={file}
												style={{
													width: '100%',
													height: '100%',
													display: 'block',
													borderRadius: 'inherit',
												}}
											/>
										</Block>
									}

									if (file.type != null) {
										return <Block key={`file-${file.id}-${index}`}>
											<FileItem file={file}>
												<div style={{ padding: '0.755rem' }}>
													<div style={{
														display: 'inline-block',
														position: 'relative',
														top: 3,
														marginRight: '0.25rem'
													}}>
														<Icon name="File" />
													</div>

													{file.name}
												</div>
											</FileItem>
										</Block>
									}

									return null;
								})}
							</BlockWrapper>
						</div>
						: null}
				</div>

				<div className="single-actions right-side">
					<div className="action-section">
						<h3>{this.props.translate('tools')}</h3>

						{activePost.creator == this.props.currentUser.id || activePost.editable ?
							this.editButton()
							: null}

						{activePost.deletable ?
							this.deleteButton()
							: null}

						{this.props.readList != null && this.props.readList.length > 0 && !this.state.loadingReadlist ?
							<div className="single-post-read-list-trigger" onClick={this.toggleRelationshipList}>
								<Icon name="Eye" />
								{percentage}% ({readCount}/{sharedWithPeopleCount}) {this.props.translate('have-read')}
							</div>
							: null}

						{this.props.readList != null && this.props.readList.length > 0 && !this.state.loadingReadlist ?
							<div style={{ width: 230 }}>
								<ProgressBar
									percentage={percentage}
									fillColor="var(--color--meta)"
								/>
							</div>
							: null}
					</div>
				</div>

				<div className="clearfix" />
			</div>
		);
	}
}


function mapStateToProps(state) {
	return {
		services: state.Services.availableServices,
		posts: state.Posts.posts,
		activePost: state.Posts.activePost,
		currentUser: state.user.currentUser,
		translate: translate(state.Languages.translations),
		reader: state.user.reader,
		readList: state.Posts.readList,
	};
}

export default withRouter(connect(mapStateToProps, {
	setActivePost,
	getPostDetails,
	deletePost,
	deletePostFromStore,
	getReaderParameters,
	setPageTitle,
	setPageActions,
	getPostReadList,
	clearPostReadList
})(SinglePost));
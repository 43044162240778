import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { translate } from '@haldor/ui';
import * as microsoftTeams from "@microsoft/teams-js";

import { setUser, getMe, getTenant } from 'actions/user';
import { getEducationGroups, getSections } from 'actions/sections';
import { getTypes } from 'actions/status';
import { getMenuItems } from 'actions/menu';
import { markNotificationAsRead } from 'actions/notifications';

import { getSchoolServices } from 'actions/schools';
import sniffer from 'sniffer';

import { getUser, getConsentStatus, onMsalTokenError } from 'lib/msal';

import schoolHandling from 'helpers/schoolHandling';
import roleHandling from 'helpers/roleHandling';
import { trackNoGroups } from 'helpers/insights';

import NotificationProvider from 'components/Providers/NotificationProvider';
import Footer from 'components/Footer/Footer';
import Error from 'components/Presentation/Error/Error';
import Menu from 'components/Menu';
import Header from 'components/HeaderV2';
import DeviceHeader from 'components/HeaderV2/Device';
import DesktopHeader from 'components/HeaderV2/Desktop';

import InteractionRequired from 'containers/Errors/InteractionRequired';
import RequiredDataError from 'containers/Errors/RequiredDataError';

import { Spinner } from 'UI';
import { Layout, Content, Sider, getActiveLanguage } from '@haldor/ui';
import packageJson from '../../package.json';

import { appInsights } from 'lib/appInsights';

import ZendeskWidget from 'lib/zendeskWidget';
import SupportLauncher from 'components/Support/SupportLauncher';

// Import routes
import MentorGroups from 'containers/Overview/Sections/MentorGroups';
import SchoolSwitch from 'components/SchoolSwitch/SchoolSwitch';
import AbsenceOverview from 'containers/Absence/AbsenceOverview';
import AttendanceOverview from 'containers/Attendance/AttendanceOverview';
import ConversationPage from 'containers/Conversation/Display/ConversationPage';
import ConversationsPage from 'containers/Conversation/Display/ConversationsPage';
import FlaggedConversations from 'containers/Conversation/FlaggedConversations';
import Courses from 'containers/Courses/Courses';
import StandardCourses from 'containers/Courses/StandardCourses';
import AllForms from 'containers/FormTemplate/Page/AllForms';
import SingleForm from 'containers/FormTemplate/Page/SingleForm';
import SingleFormTemplate from 'containers/FormTemplate/Page/SingleFormTemplatePage';
import IndexWrapper from 'containers/IndexWrapper';
import UserLogs from 'containers/Logs/UserLogs';
import ListPage from 'containers/Matrix/ListPage';
import TestPage from 'containers/Matrix/TestPage';
import Meeting from 'containers/Meeting/Display/Meeting';
import MeetingInstance from 'containers/Meeting/Display/MeetingInstance';
import MyMeetings from 'containers/Meeting/Display/MyMeetings';
import Notifications from 'containers/Notifications';
import ConditionReceiver from 'containers/ConditionReceiver';
import GroupsPage from 'containers/Groups/GroupsPage';
import Schedule from 'containers/Schedule/Schedule';
import AllPosts from 'containers/Posts/AllPosts';
import AdministrateAdjustments from 'containers/AdditionalAdjustments/Administration/AdministrateAdjustments';
import CourseProgress from 'containers/Progress/Course';
import UserProgress from 'containers/Progress/User';
import Guardian from 'containers/Guardian/Guardian';
import Search from 'containers/Search';
import SectionWrapper from 'containers/Section/SectionWrapper';
import SectionBaseGroupPage from 'containers/BaseGroups/Display/SectionBaseGroupPage';
import SinglePost from 'containers/SinglePages/SinglePost';
import SinglePreschoolDocument from 'containers/SinglePages/SinglePreschoolDocument';
import Staff from 'containers/User/Staff';
import SubjectPage from 'containers/Verdict/Display/SubjectPage';
import CreateMeetingWizard from 'containers/Meeting/Form/CreateMeetingWizard';
import UserBookmarks from 'containers/Bookmarks/User';
import Admin from 'containers/Admin/Index';
import SingleAssignmentV1 from 'containers/SinglePages/SingleAssignmentV1';
import UserDocuments from 'components/List/UserDocuments';
import Plans from 'containers/Plans/Plans';
import SubmissionsView from 'containers/SinglePages/Assignment/SubmissionsView';
import PlanningWrapper from 'containers/SinglePages/Plan/PlanningWrapper';
import SectionProgress from 'containers/Progress/Section';
import SingleCourse from 'containers/SinglePages/SingleCourse';
import SingleLesson from 'containers/Lesson/Single';
import SingleCalendarEvent from './CalendarEvent/SingleCalendarEvent';
import LanguageRender from 'components/LanguageRender/LanguageRender';
import SMSOverview from 'containers/SMS/Overview';
import SingleSMS from 'containers/SMS/Single';
import NewVerdictPage from './Verdict/Page/NewVerdict';

import 'assets/css/main.scss';
import MyAssignmentsWrapper from './Assignments/MyAssignmentsWrapper';

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			background: '#f5f5f5',
			loading: true,
			showSession: false,
			error: null,
		};
	}

	getData = () => {
		const user = getUser();
		let timer = setTimeout(() => {
			this.setState({ showSession: true });
		}, 1000 * 5);

		if (user == null) {
			onMsalTokenError(null, 'login_required');
			return false;
		}

		if (user != null) {
			getConsentStatus();
			this.props.getTenant().then(() => {
				Promise.all([
					this.props.setUser(user),
					this.props.getMe(),
					this.props.getTypes(getActiveLanguage()),
				]).then(() => {
					const { currentUser } = this.props;

					if (appInsights != null) {
						appInsights.context.application.ver = packageJson.version;
						appInsights.setAuthenticatedUserContext(currentUser.logId, null, true);
						appInsights.trackTrace({ message: 'User Logged in' });
					}

					if (currentUser.schools != null && currentUser.schools.length > 0) {
						schoolHandling.fetchSchool(this.props.activeSchool, currentUser);
						roleHandling.fetchRole(this.props.activeRole, currentUser);
					}

					if (this.props.activeSchool != null) {
						this.props.getSchoolServices(this.props.activeSchool)
						this.props.getEducationGroups().then(() => {
							if (this.props.groups == null || this.props.groups.length == 0) {
								trackNoGroups(currentUser.id);
							}
						});
					}

					this.setState({ loading: false });
					clearTimeout(timer);

					this.props.getMenuItems();
				}).catch((error) => {
					this.setState({ loading: false, error: error });
				});
			})
		} else {
			this.setState({ loading: false });
		}
	}

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';

		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	componentDidMount() {
		this.getData();
		if (microsoftTeams.app.isInitialized()) {
			microsoftTeams.app.getContext().then((context) => {
				if (context == null) {
					return false;
				}
				if (context.page.subPageId != null && context.page.subPageId != "") {
					var notificationId = this.getParameterByName('notificationId', context.page.subPageId);
					if (notificationId != null && notificationId != '' && notificationId !== '0') {
						this.props.markNotificationAsRead(notificationId);
					}
					this.props.history.push(context.page.subPageId);
				}
			});
			this.setState({ background: '#f5f5f5' });
		}
	}

	isDesktop = () => {
		if (sniffer.isTablet) {
			return true;
		}

		if (sniffer.isDesktop) {
			return true;
		}

		return false;
	}

	render() {
		if (this.state.loading) {
			return <div style={{ textAlign: 'center' }}>
				<Spinner center />

				{this.state.showSession ?
					<span style={{ color: 'var(--color--meta)' }}>
						Session ID: {localStorage.getItem('hsid')}
					</span>
					: null}
			</div>
		}

		if (this.state.error != null) {
			if (this.state.error?.data.errorCode == 'invalid_grant' || this.state.error?.data.errorCode == 'interaction_required') {
				return <InteractionRequired error={this.state.error?.data} />
			} else {
				return <RequiredDataError error={this.state.error} />
			}
		}

		if (this.props.currentUser != null) {

			if (!this.props.services.haldorEducation && !this.props.services.haldorCoursePlanning) {
				return (
					<div>
						<div className="login-content">
							<h1>
								{this.props.translate('haldor-education-not-activated')}
							</h1>

							<p className="login-content__subtitle">
								{this.props.translate('not-a-customer-2')}
							</p>
						</div>

						<Footer />
					</div>
				);
			}

			const { currentUser } = this.props;
			if (currentUser.schools == null || currentUser.schools.length < 1 || currentUser.roles == null || currentUser.roles.length < 1) {
				return (
					<div style={{ backgroundColor: this.state.background }}>
						<div className="login-content">
							<h1>
								{this.props.translate('something-went-wrong')}
							</h1>

							<p className="login-content__subtitle">
								{this.props.translate('error-no-schools-or-roles')}
							</p>
							<p>&nbsp;</p>
							<p className="login-content__helptext" style={{ cursor: 'pointer' }} onClick={() => { navigator.clipboard.writeText(currentUser.userId) }}>
								UserObjectId: {currentUser.userId}
							</p>
						</div>

						<Footer />
					</div>
				);
			}

			return (
				<Fragment>
					<NotificationProvider />
					<Error />
					<ZendeskWidget language={this.props.tenant.lcid} />
					<SupportLauncher translate={this.props.translate} />

					{!microsoftTeams.app.isInitialized() && this.isDesktop() ?
						<div>
							<div className="desktop-header-spacing" />
							<DesktopHeader />
						</div>
						: null}

					<Layout>
						<Sider>
							{this.isDesktop() ?
								<Menu />
								: null}
						</Sider>

						<Content>
							{!this.isDesktop() ?
								<DeviceHeader />
								: <Header />}

							<Switch>
								<Route path="/" exact component={IndexWrapper} />

								{/* Overview sub pages */}
								<Route path="/administration" component={Admin} />
								<Route path="/assignments" component={MyAssignmentsWrapper} />
								<Route path="/posts" component={AllPosts} />
								<Route path="/plans" component={Plans} />
								<Route path="/overview/mentor" component={MentorGroups} />
								<Route path="/schedule" component={Schedule} />
								<Route path="/notifications" component={Notifications} />

								<Route path="/assignment/:assignmentId/task/:taskId/documents" component={UserDocuments} />
								<Route path="/assignment/:assignmentId/submission/:submissionId/documents" component={SubmissionsView} />
								<Route path="/assignment/:assignmentId" component={SingleAssignmentV1} />

								<Route path="/plan/:planId" component={PlanningWrapper} />

								<Route path="/additionaladjustments" component={AdministrateAdjustments} />

								<Route path="/groups" component={GroupsPage} exact />
								<Route path="/groups/:groupId" component={SectionWrapper} exact />
								<Route path="/groups/:groupId/progress/:userId" component={SectionProgress} exact />
								<Route path="/groups/:groupId/course/:courseId" component={CourseProgress} exact />
								<Route path="/groups/:groupId/course/:courseId/student/:userId" component={NewVerdictPage} />
								<Route path="/groups/:groupId/basegroups" component={SectionBaseGroupPage} exact />

								<Route path="/evaluations" component={SubjectPage} />

								<Route path="/logs" component={UserLogs} />

								<Route path="/search" component={Search} exact />

								<Route path="/preschooldocument/:id" component={SinglePreschoolDocument} />
								<Route path="/post/:id" component={SinglePost} />

								<Route path="/editMatrix/:matrixId" component={TestPage} />
								<Route path="/matrix" component={TestPage} />
								<Route path="/matrixList" component={ListPage} />

								<Route path="/courses" component={Courses} exact />
								<Route path="/courses/standard" component={StandardCourses} exact />
								<Route path="/course/:courseId" component={SingleCourse} />

								<Route path="/lesson/:id" component={SingleLesson} />

								<Route path="/calendarEvent/:id" component={SingleCalendarEvent} />

								<Route path="/report/absence" component={AbsenceOverview} />
								<Route path="/report/attendance" component={AttendanceOverview} />

								<Route path="/user/:userId/progress" component={UserProgress} />
								<Route path="/progress" component={UserProgress} />

								<Route path="/sms" exact component={SMSOverview} />
								<Route path="/sms/:id" component={SingleSMS} />

								<Route path="/user/:userId" component={Staff} />

								<Route path="/guardian/:userId" component={Guardian} />
								<Route path="/guardian" component={Guardian} />
								<Route path="/conversations" component={ConversationsPage} exact />
								<Route path="/conversations/admin/flagged" component={FlaggedConversations} />
								<Route path="/conversations/:id" component={ConversationPage} />

								<Route path="/forms/templates" component={AllForms} exact />
								<Route path="/form/:id" component={SingleForm} />
								<Route path="/forms/template/:id" component={SingleFormTemplate} />

								<Route path="/meetings/wizard" component={CreateMeetingWizard} exact />
								<Route path="/meetings" component={MyMeetings} exact />
								<Route path="/meeting/:id" component={Meeting} exact />
								<Route path="/meeting/:id/instance/:instanceId" component={MeetingInstance} exact />

								<Route path="/condition" component={ConditionReceiver} />

								<Route path="/bookmarks" component={UserBookmarks} />

								<Route path="/language" component={LanguageRender} />
								<Route path="/switch" component={SchoolSwitch} />
							</Switch>

							<Footer />
						</Content>
					</Layout>
				</Fragment>
			);
		} else {
			return <Spinner center />
		}
	}
}

function mapStateToProps(state) {
	return {
		translate: translate(state.Languages.translations),
		languages: state.Languages.languages,
		currentUser: state.user.currentUser,
		services: state.Services.availableServices,
		activeSchool: state.user.activeSchool,
		activeRole: state.user.activeRole,
		groups: state.sections.educationGroups,
		tenant: state.user.tenant,
	};
}

export default withRouter(connect(mapStateToProps, {
	setUser,
	getEducationGroups,
	getTenant,
	getSchoolServices,
	getMe,
	getSections,
	getMenuItems,
	getTypes,
	markNotificationAsRead,
})(App));